import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgModule } from "@angular/core";
import { AppComponent } from "./app.component";
import { CoreModule } from "./core/core.module";
import { NgHttpLoaderModule } from "ng-http-loader";
import { environment } from "../environments/environment";

import { MarkdownModule } from "ngx-markdown";
import { IppNavigationModule } from "@premium-portal/navigation";
import { IppThemesModule } from "@premium-portal/themes";
import { RouterModule } from "@angular/router";
import { IppTranslateModule } from "@premium-portal/translate";
import { routes } from "./app.routes";
import { LanguageCode, languageLabels } from "@premium-portal/types";
import { EnvironmentName } from "./core/enums";

const getSupportedLanguages = () => {
  if (environment.name === EnvironmentName.Local || environment.name === EnvironmentName.Development) {
    return {
      ...languageLabels,
      ["template" as LanguageCode]: "Template",
    };
  }

  return languageLabels;
};

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(routes),
    CoreModule,
    IppTranslateModule.forRoot({
      supportedLanguageLabel: getSupportedLanguages(),
      production: environment.production,
    }),
    NgHttpLoaderModule.forRoot(),
    MarkdownModule.forRoot(),
    IppThemesModule.forRoot(),
    IppNavigationModule.forRoot({
      environment: environment.target,
      shouldUseCookieAuthentication: true,
      shouldUseLocalRouter: true,
    }),
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
