import { NavigationService } from "./../../navigation.service";
import { Component, EventEmitter, inject, Input, Output } from "@angular/core";
import { ProcessedNotification } from "../models/notification.model";
import { Router } from "@angular/router";
import { LibRoutes } from "@premium-portal/types";
import { ReadonlyRecord } from "../types/readonly-record";
import { SeverityLevel } from "../enums/severity-level.enum";
import { CommonModule } from "@angular/common";
import { IxModule } from "@siemens/ix-angular";
import { EllipsisPipe } from "../../pipes/ellipsis.pipe";
import { TranslateModule } from "@ngx-translate/core";
import { MomentModule } from "ngx-moment";

@Component({
  selector: "ipp-notifications",
  templateUrl: "./notification.component.html",
  styleUrls: ["./notification.component.scss"],
  standalone: true,
  imports: [CommonModule, IxModule, EllipsisPipe, TranslateModule, MomentModule],
})
export class NotificationComponent {
  @Input() notifications: ProcessedNotification[] = [];
  @Output() readNotification: EventEmitter<string> = new EventEmitter();
  protected readonly router = inject(Router);
  protected readonly navigationService = inject(NavigationService);
  protected readonly LIMIT_CHARACTERS = 50;

  public readonly severityLevel: ReadonlyRecord<string, string> = {
    [SeverityLevel.ALARM]: "var(--theme-color-alarm)",
    [SeverityLevel.WARNING]: "var(--theme-color-warning)",
    [SeverityLevel.INFORMATION]: "var(--theme-color-info)",
    undefined: "transparent",
  };

  public notifyAsRead(event: Event, notification: ProcessedNotification): void {
    event.stopPropagation();
    event.preventDefault();
    this.readNotification.emit(notification.notificationId);
  }

  public preventDefault(event: Event): void {
    event.stopPropagation();
    event.preventDefault();
  }

  public navigateToNotificationsPage(): void {
    this.navigationService.navigate(LibRoutes.NOTIFICATIONS_PAGE);
  }
}
