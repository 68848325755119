import { Component, OnInit } from "@angular/core";
import { of, Observable } from "rxjs";
import { AppSettings } from "../app/core/config/app-settings.config";
import { AuthenticationService } from "./core/authentication";
import { UserInfo } from "@premium-portal/types";
import { getAppVersion } from "./core/functions";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
})
export class AppComponent implements OnInit {
  readonly filteredUrlPatterns: string[] = [...AppSettings.filteredUrlPatterns];
  public show$ = of(false);
  public userInfo$: Observable<UserInfo>;
  public version: string;

  constructor(protected authService: AuthenticationService) {}

  ngOnInit(): void {
    this.show$ = this.authService.authStateChecked();
    this.userInfo$ = this.authService.getUserInfo();
    this.version = `${getAppVersion().major}.${getAppVersion().minor}.${getAppVersion().patch}`;
  }

  signIn(): void {
    this.authService.signIn();
  }

  signOut(): void {
    this.authService.signOut();
  }
}
