import { AppHttpErrorResponse, ErrorEventBusService, ErrorService, ErrorTypes, Events } from "@premium-portal/events";
import { ErrorHandler, Injectable, OnDestroy } from "@angular/core";
import { EnvironmentName, HttpStatusCode, ToastType } from "../../../app/core/enums";
import { environment } from "../../../environments/environment";
import { NavigationExtras, Router } from "@angular/router";
import { UriComponent } from "../types/uri-component.type";
import { Subscription } from "rxjs";
import { GenericErrorResponseDto } from "../models/generic-error-response-dto";

@Injectable()
export class AppErrorHandler extends ErrorHandler implements OnDestroy {
  private errorEventBusSubscription: Subscription;

  constructor(private router: Router, protected errorEventBus: ErrorEventBusService, protected errorService: ErrorService) {
    super();

    this.errorEventBusSubscription = this.errorEventBus.on(Events.ErrorSelected, (errorResponse: AppHttpErrorResponse<unknown>) =>
      this.catchErrorResponse(errorResponse)
    );
  }

  // based on error type (blocking/non-blocking) show toast or page error
  private catchErrorResponse(errorResponse: AppHttpErrorResponse<unknown>) {
    const error = this.errorService.errorDictionary(errorResponse);

    const errorType = error["TYPE"];
    const errorRoute = error["ROUTE"];
    const errorObj = error["ERROR"];

    if (errorType === ErrorTypes.TOAST) {
      this.errorService.show(errorResponse.message, ToastType.Error);
    }

    if (errorType === ErrorTypes.PAGE) {
      this.navigateAfterError(errorRoute, errorObj);
    }
  }

  private navigateAfterError(route: string, error: AppHttpErrorResponse<GenericErrorResponseDto>): void {
    const { queryParams } = this.resolveQueryParams(error);
    const { traceId, status } = queryParams;

    const extras = { queryParams: { status }, skipLocationChange: false, queryParamsHandling: "merge" } as NavigationExtras;

    let routeElements = [];

    if (HttpStatusCode.InternalServerError === error.status) {
      delete extras.queryParams;
      routeElements = [route, encodeURI(traceId as string)];
    } else {
      routeElements = [route];
    }

    this.router.navigate(routeElements, extras);
  }

  private resolveQueryParams(response: AppHttpErrorResponse<GenericErrorResponseDto>): { queryParams: Record<string, UriComponent> } {
    const queryParams: Record<string, UriComponent> = { status: response.status };

    if (environment.name === EnvironmentName.Local || environment.name === EnvironmentName.Development) {
      queryParams.message = response.error?.exceptionStackTrace || response.message;
    } else {
      queryParams.message = response.message;
    }

    queryParams.traceId = response.error?.traceId;

    const extras: { queryParams: Record<string, UriComponent> } = {
      queryParams,
    };

    return extras;
  }

  public ngOnDestroy(): void {
    if (this.errorEventBusSubscription) this.errorEventBusSubscription.unsubscribe();
  }
}
