<div class="notification-bell">
  <ix-menu-item tab-icon="alarm-bell" [notifications]="unreadMessagesLength()" #triggerNotifications>
    <ng-content></ng-content>
  </ix-menu-item>
  <ix-dropdown
    class="notification-bell__container"
    [ixDropdownTrigger]="triggerNotifications"
    placement="right-start"
    [ngClass]="{'notification-bell__container--has-items': hasNotifications(), 'notification-bell__container--no-items': !hasNotifications() }">
    <div class="notification-bell__list-header">
      <div class="notification-bell__shout-icon-container">
        <ix-icon name="shout" size="32" />
      </div>
      <div class="notification-bell__header-title text-l-title-single">
        {{ "IPP_NAVIGATION.MENU.NOTIFICATION_BELL.TITLE" | translate }}
      </div>
      <a class="default-link" *ngIf="hasNotifications()" (click)="markAllAsRead($event)">
        {{ "IPP_NAVIGATION.MENU.NOTIFICATION_BELL.MARK_ALL_NOTIFICATIONS_AS_READ" | translate }}
      </a>
    </div>

    <ng-container>
      @if (hasNotifications()) {
      <ipp-notifications [notifications]="notificationList()" (readNotification)="markAsRead($event)" />
      } @else {
      <div class="notification-bell__notification-list-empty">
        <svg id="all-caught-up-icon" width="64" height="65" viewBox="0 0 64 65" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M35.4669 7.28151L39.3787 10.6082C40.2312 11.3332 41.29 11.7717 42.4053 11.8619L47.5237 12.2756C50.1312 12.4864 52.2012 14.5564 52.412 17.1638L52.8257 22.2822C52.9158 23.3976 53.3544 24.4564 54.0794 25.3089L57.4061 29.2207C59.1008 31.2134 59.1008 34.1409 57.4061 36.1337L54.0794 40.0455C53.3544 40.8979 52.9158 41.9567 52.8257 43.0721L52.412 48.1905C52.2012 50.7979 50.1312 52.868 47.5237 53.0787L42.4053 53.4924C41.29 53.5826 40.2312 54.0212 39.3787 54.7461L35.4669 58.0728C33.4742 59.7675 30.5467 59.7675 28.5539 58.0728L24.6421 54.7461C23.7897 54.0212 22.7309 53.5826 21.6155 53.4924L16.4971 53.0787C13.8897 52.868 11.8196 50.7979 11.6089 48.1905L11.1951 43.0721C11.105 41.9567 10.6664 40.8979 9.94148 40.0455L6.61476 36.1337C4.92008 34.1409 4.92008 31.2134 6.61476 29.2207L9.94148 25.3089C10.6664 24.4564 11.105 23.3976 11.1951 22.2822L11.6089 17.1638C11.8196 14.5564 13.8897 12.4864 16.4971 12.2756L21.6155 11.8619C22.7309 11.7717 23.7897 11.3332 24.6421 10.6082L28.5539 7.28151C30.5467 5.58683 33.4742 5.58683 35.4669 7.28151ZM32.0118 11.3443L28.0973 14.671C26.3927 16.1206 24.2755 16.9976 22.0452 17.1779L16.9268 17.5916L16.5111 22.7119C16.3309 24.9423 15.4539 27.0595 14.0043 28.764L10.6776 32.6758L14.0043 36.5903C15.4539 38.2949 16.3309 40.4121 16.5111 42.6424L16.9249 47.7608L22.0452 48.1764C24.2755 48.3567 26.3927 49.2337 28.0973 50.6833L32.0091 54.01L35.9236 50.6833C37.6281 49.2337 39.7453 48.3567 41.9756 48.1764L47.0941 47.7627L47.5097 42.6424C47.69 40.4121 48.5669 38.2949 50.0165 36.5903L53.3433 32.6785L50.0165 28.764C48.5669 27.0595 47.69 24.9423 47.5097 22.7119L47.096 17.5935L41.9756 17.1779C39.7453 16.9976 37.6281 16.1206 35.9236 14.671L32.0118 11.3443ZM42.042 23.1394L45.8128 26.9102L29.3333 43.4587L19.4479 33.5733L23.2187 29.8025L29.3333 35.917L42.042 23.1394Z" />
        </svg>
        <p>
          <span [innerHTML]="'IPP_NAVIGATION.MENU.NOTIFICATION_BELL.ALL_CAUGHT_UP' | translate"></span>
        </p>
      </div>
      }

      <div class="notification-bell__list-footer">
        {{ "IPP_NAVIGATION.MENU.NOTIFICATION_BELL.OPEN_THE" | translate }}
        <a (click)="navigateToNotificationsPage()" class="default-link">
          {{ "IPP_NAVIGATION.MENU.NOTIFICATION_BELL.FULL_NOTIFICATIONS_PAGE" | translate }}
        </a>
        <ix-icon name="arrow-right" size="16"></ix-icon>
      </div>
    </ng-container>
  </ix-dropdown>
</div>
