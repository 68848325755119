import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { Component, computed, DestroyRef, inject, OnInit, signal } from "@angular/core";
import { NotificationAction, ProcessedNotification } from "./models/notification.model";
import { NotificationsSignalRService } from "./services/notifications-signal-r.service";
import { NotificationService } from "./services/notification.service";
import { LibRoutes } from "@premium-portal/types";
import { NavigationService } from "../navigation.service";
import { CommonModule } from "@angular/common";
import { IxModule } from "@siemens/ix-angular";
import { TranslateModule } from "@ngx-translate/core";
import { NotificationComponent } from "./notification/notification.component";

@Component({
  selector: "ipp-menu-item-notification-bell",
  templateUrl: "./notification-bell.component.html",
  styleUrls: ["./notification-bell.component.scss"],
  standalone: true,
  imports: [CommonModule, IxModule, TranslateModule, NotificationComponent],
})
export class NotificationBellComponent implements OnInit {
  private readonly notificationService = inject(NotificationService);
  private readonly notificationSignalRService = inject(NotificationsSignalRService);
  private readonly destroyRef = inject(DestroyRef);
  private readonly navigationService = inject(NavigationService);

  public notificationList = signal<ProcessedNotification[]>([]);
  public unreadMessagesLength = computed(() => this.notificationList().filter((x) => !x.read).length);
  public hasNotifications = computed(() => this.notificationList().length > 0);

  ngOnInit(): void {
    this.loadNotificationList();
    this.onNotificationReceiveMessage();
    this.onNotificationReceiveActionMessage();
  }

  public loadNotificationList(): void {
    this.notificationService
      .getAllNotifications()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((response) => this.notificationList.set(response));
  }

  public markAllAsRead(event: Event): void {
    event.stopPropagation();
    event.preventDefault();
    this.notificationService
      .markAllAsRead()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this.readAllNotifications());
  }

  public markAsRead(notificationId: string): void {
    this.notificationService
      .markAsReadByNotificationId(notificationId)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this.readNotification(notificationId));
  }

  public onNotificationReceiveMessage(): void {
    this.notificationSignalRService
      .getMessage()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((message: ProcessedNotification) => this.addNotification(message));
  }

  public onNotificationReceiveActionMessage(): void {
    this.notificationSignalRService
      .getActionMessage()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(({ action, notificationId }) => {
        switch (action) {
          case NotificationAction.Read:
            this.readNotification(`${notificationId}`);
            break;
          case NotificationAction.Delete:
            this.deleteNotification(`${notificationId}`);
            break;
          case NotificationAction.ReadAll:
            this.readAllNotifications();
            break;
          case NotificationAction.DeleteAll:
            this.deleteAllNotifications();
            break;
          default:
            break;
        }
      });
  }

  private readNotification(notificationId: string): void {
    const notifications = [...this.notificationList()];
    const notification = notifications.find((x) => x.notificationId === notificationId);
    if (!notification) {
      return;
    }
    notification.read = true;
    this.notificationList.set(notifications);
  }

  private readAllNotifications(): void {
    this.notificationList.update((notifications) => {
      return notifications.map((x) => ({ ...x, read: true }));
    });
  }

  private deleteNotification(notificationId: string): void {
    this.notificationList.update((notifications) => {
      return notifications.filter((i) => i.notificationId !== notificationId);
    });
  }

  private deleteAllNotifications(): void {
    this.notificationList.update(() => []);
  }

  public addNotification(notification: ProcessedNotification): void {
    const notifications = [...this.notificationList()];
    // identify same notification using notificationId to then update it with new values
    const index: number = notifications.findIndex((nt) => nt.notificationId === notification.notificationId);

    if (index >= 0) {
      // if notification is found on the list, update it
      notifications[index] = notification;
    } else {
      // if not, add new value at top
      notifications.unshift(notification);
    }

    this.notificationList.set(notifications);
  }

  public navigateToNotificationsPage(): void {
    this.navigationService.navigate(LibRoutes.NOTIFICATIONS_PAGE);
  }
}
