import { Inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Utils, NavigationConfig } from "@premium-portal/types";
import { HttpClient } from "@angular/common/http";
import { ProcessedNotification } from "../models/notification.model";

@Injectable({
  providedIn: "root",
})
export class NotificationService {
  public static readonly NOTIFICATIONS_ENDPOINT = "premium-portal/notifications";
  public static readonly USER_NOTIFICATIONS_ENDPOINT = "premium-portal/user-notifications";

  private readonly baseUrl: string;
  private static readonly LIMIT = 25;

  constructor(@Inject(Utils.NAVIGATION_CONFIG) config: NavigationConfig, protected httpClient: HttpClient) {
    this.baseUrl = config.shouldUseCookieAuthentication ? config.environment.gatewayUrl.toString() : config.environment.apiUrl.toString();
  }

  public getAllNotifications(): Observable<ProcessedNotification[]> {
    return this.httpClient.get<ProcessedNotification[]>(`${this.baseUrl}${NotificationService.NOTIFICATIONS_ENDPOINT}/latest`);
  }

  public markAllAsRead(): Observable<void> {
    return this.httpClient.patch<void>(`${this.baseUrl}${NotificationService.NOTIFICATIONS_ENDPOINT}`, {});
  }

  public markAsReadByNotificationId(notificationId: string): Observable<void> {
    return this.httpClient.patch<void>(`${this.baseUrl}${NotificationService.NOTIFICATIONS_ENDPOINT}/${notificationId}`, {});
  }

  public deleteAll(): Observable<void> {
    return this.httpClient.delete<void>(`${this.baseUrl}${NotificationService.NOTIFICATIONS_ENDPOINT}`);
  }

  public deleteById(notificationId: string): Observable<void> {
    return this.httpClient.delete<void>(`${this.baseUrl}${NotificationService.NOTIFICATIONS_ENDPOINT}/${notificationId}`);
  }
}
