import {ModuleWithProviders, NgModule} from "@angular/core";
import { CommonModule } from "@angular/common";
import { CountriesConfig } from "@premium-portal/types";
import { CountriesService } from "./countries.service";
import { COUNTRIES_TOKEN } from "./countries.token";

@NgModule({
  imports: [CommonModule],
})
export class IppCountriesModule {
  static forRoot(config: CountriesConfig): ModuleWithProviders<IppCountriesModule> {
    const { environment } = config;
    if (!environment) {
      throw new Error("Environment is not set");
    }

    if (environment.isUnstable) {
      console.warn("You're pointing to an unstable Industry Premium Portal environment. This might lead to erratic behaviour.");
    }

    return {
      ngModule: IppCountriesModule,
      providers: [
        CountriesService, { provide: COUNTRIES_TOKEN, useValue: config }],
    };
  }
}
