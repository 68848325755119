import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { BreadcrumbElement } from "../models/breadcrumb-element.model";

@Injectable({ providedIn: "root" })
export class IppBreadcrumbsService {
  private static _breadcrumbs: BehaviorSubject<BreadcrumbElement[]>;

  constructor() {
    if (!IppBreadcrumbsService._breadcrumbs) {
      IppBreadcrumbsService._breadcrumbs = new BehaviorSubject([] as BreadcrumbElement[]);
    }
  }

  setBreadcrumbs(breadcrumbs: BreadcrumbElement[]): void {
    IppBreadcrumbsService._breadcrumbs.next(breadcrumbs);
  }

  getBreadcrumbs(): BreadcrumbElement[] {
    return IppBreadcrumbsService._breadcrumbs.getValue();
  }

  getBreadcrumbs$(): Observable<BreadcrumbElement[]> {
    return IppBreadcrumbsService._breadcrumbs.asObservable();
  }
}
