import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "ellipsis",
  standalone: true,
})
export class EllipsisPipe implements PipeTransform {
  transform(value: string, limit: number): string {
    if (!value) return "";
    const trail = limit && "...";
    return value.length > limit ? `${value.substring(0, limit)}${trail}` : value;
  }
}
