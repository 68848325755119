import { LibRoutes } from "../lib-router-path";

export interface NavigationLink {
  title: string;
  url: string;
  type: LinkType;
}

export interface SidebarNavigationLink {
  key: LibRoutes;
  url: string;
}

export enum LinkType {
  ROUTER_LINK,
  HREF,
}
