import { APP_INITIALIZER, Injector, ModuleWithProviders, NgModule } from "@angular/core";
import { CommonModule, LOCATION_INITIALIZED } from "@angular/common";
import { TranslateLoader, TranslateModule, TranslateService } from "@ngx-translate/core";
import { HttpBackend } from "@angular/common/http";
import { NgHttpLoaderModule } from "ng-http-loader";
import { EMPTY, from, Observable } from "rxjs";
import { catchError, first, map, mergeMap, tap } from "rxjs/operators";
import { MultiTranslateHttpLoader } from "ngx-translate-multi-http-loader";
import { DEFAULT_LANGUAGE_CODE, LanguageCode, LanguageLabel, languageLabels, TranslateLoaderConfig } from "@premium-portal/types";
import { getCurrentLanguage } from "./get-current-language";

let customLanguageLabels: LanguageLabel = languageLabels;
let fallbackLanguage: LanguageCode = LanguageCode.English;
const currentLanguage = () => getCurrentLanguage("user-settings", fallbackLanguage, customLanguageLabels);

// https://github.com/ngx-translate/core/issues/517#issuecomment-299637956
const appInitializerFactory = (translate: TranslateService, injector: Injector) => (): Observable<void> =>
  from(injector.get(LOCATION_INITIALIZED, Promise.resolve())).pipe(
    map(() => translate.setDefaultLang(fallbackLanguage)),
    mergeMap(() => translate.use(currentLanguage()).pipe(first())),
    tap(() => !translateLoaderConfig.production && console.log(`✅ Successfully initialized '${currentLanguage()}' as current language.'`)),
    catchError(() => {
      console.error(`Problem with '${currentLanguage()}' default language '${fallbackLanguage}' initialization.'`);
      return EMPTY;
    })
  );

let translateLoaderConfig: TranslateLoaderConfig = {
  production: false,
};

const loaderTranslation = (): TranslateModule => {
  return {
    defaultLanguage: fallbackLanguage,
    loader: {
      provide: TranslateLoader,
      useFactory: (http: HttpBackend) => {
        const prefix = translateLoaderConfig.prefix ?? "./assets/i18n/";
        const suffix = translateLoaderConfig.suffix ?? ".json";

        return new MultiTranslateHttpLoader(http, [
          { prefix: "./assets/i18n/translate/" },
          {
            prefix,
            suffix,
          },
        ]);
      },
      deps: [HttpBackend],
    },
  };
};

@NgModule({
  imports: [CommonModule, TranslateModule.forRoot(loaderTranslation()), NgHttpLoaderModule.forRoot()],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      deps: [TranslateService, Injector],
      multi: true,
    },
    { provide: DEFAULT_LANGUAGE_CODE, useValue: currentLanguage() },
  ],
})
export class IppTranslateModule {
  /**
   * Custom location for translation files
   * @param config
   */
  static forRoot(config?: TranslateLoaderConfig): ModuleWithProviders<IppTranslateModule> {
    if (config) {
      const { production, prefix, suffix, supportedLanguageLabel, defaultLanguage } = config;

      if (production) {
        translateLoaderConfig = {
          ...translateLoaderConfig,
          production,
        };
      }

      if (prefix) {
        translateLoaderConfig = {
          ...translateLoaderConfig,
          prefix,
        };
      }

      if (suffix) {
        translateLoaderConfig = {
          ...translateLoaderConfig,
          suffix,
        };
      }

      customLanguageLabels = supportedLanguageLabel ?? languageLabels;
      fallbackLanguage = (defaultLanguage as LanguageCode) ?? LanguageCode.English;
    }

    return {
      ngModule: IppTranslateModule,
    };
  }
}
