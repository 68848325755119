import { ModuleWithProviders, NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { HttpUseCredentialsInterceptor } from "./http-use-credentials.interceptor";
import { SessionService } from "./session.service";
import { SessionConfig, Utils } from "@premium-portal/types";

@NgModule({
  imports: [CommonModule],
  providers: [{ provide: HTTP_INTERCEPTORS, useClass: HttpUseCredentialsInterceptor, multi: true }],
})
export class SharedAuthIppCommonSessionModule {
  static forRoot(config: SessionConfig): ModuleWithProviders<SharedAuthIppCommonSessionModule> {
    // User config get logged here
    return {
      ngModule: SharedAuthIppCommonSessionModule,
      providers: [SessionService, { provide: Utils.SESSION_CONFIG, useValue: config }],
    };
  }
}
