import { CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, NgModule, Optional, SkipSelf } from "@angular/core";
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from "@angular/common/http";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";

import { AppErrorHandler } from "./error/app-error-handler";
import { AppHttpInterceptor } from "./interceptors/app-http.interceptor";
import { NotFoundComponent, PageLoaderComponent } from "./components";
import { MainLayoutComponent } from "./layouts";
import { SharedModule } from "../shared/shared.module";
import { AppSettings } from "./config/app-settings.config";
import { SharedAuthIppCommonSessionModule } from "@premium-portal/ipp-common-session";
import { environment } from "../../environments/environment";
import { IppBreadcrumbsModule } from "@premium-portal/base-components";
import { IppUniversalCustomerAgreementModule } from "@premium-portal/universal-customer-agreement";
import { IppComplianceModule } from "@premium-portal/compliance";
import { IppCountriesModule } from "@premium-portal/countries";

@NgModule({
  providers: [
    provideHttpClient(withInterceptorsFromDi()),
    { provide: ErrorHandler, useClass: AppErrorHandler },
    { provide: HTTP_INTERCEPTORS, useClass: AppHttpInterceptor, multi: true },
  ],
  declarations: [MainLayoutComponent, NotFoundComponent, PageLoaderComponent],
  imports: [
    CommonModule,
    RouterModule,
    SharedModule,
    /*Libs originally from case*/
    IppBreadcrumbsModule.forRoot(),

    SharedAuthIppCommonSessionModule.forRoot({
      logLevel: AppSettings.logLevel,
      applicationName: AppSettings.appName,
      teamName: AppSettings.teamName,
      internalApi: {
        bffUrl: environment.target.url.toString(),
      },
    }),
    IppUniversalCustomerAgreementModule.forRoot({
      environment: environment.target,
      shouldUseLocalRouter: true,
      shouldUseCookieAuthentication: true,
    }),
    IppCountriesModule.forRoot({
      environment: environment.target,
      shouldUseLocalRouter: true,
      shouldUseCookieAuthentication: true,
    }),
    IppComplianceModule.forRoot({
      environment: environment.target,
      shouldUseLocalRouter: true,
      shouldUseCookieAuthentication: true,
    }),
  ],
  exports: [MainLayoutComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error("CoreModule is already loaded. Import it in the AppModule only.");
    }
  }
}
