/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from "@angular/core";
import { Subject, Subscription, filter, map } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class ErrorEventBusService {
  private subject = new Subject<any>();

  on(events: Events, action: any): Subscription {
    return this.subject
      .pipe(
        filter((e: EmitEvent) => e.name === events),
        map((event: EmitEvent) => event.value)
      )
      .subscribe(action);
  }

  emit(event: EmitEvent) {
    this.subject.next(event);
  }
}

export class EmitEvent {
  constructor(public name: any, public value?: any) {}
}

export enum Events {
  ErrorSelected = "ErrorSelected",
}

export enum ErrorTypes {
  TOAST = "Toast",
  PAGE = "Page",
}
