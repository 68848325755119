<ipp-navigation [userInfo]="userInfo$ | async" [version]="version" (signInClicked)="signIn()" (signOutClicked)="signOut()"></ipp-navigation>
<!-- Show a loading animation when doing a API Call -->
<ng-http-loader
  [backdrop]="true"
  [debounceDelay]="100"
  [minDuration]="300"
  backdropBackgroundColor="#000000"
  backgroundColor="#00ffb9"
  opacity=".6"
  spinner="sk-three-bounce"
  [filteredUrlPatterns]="filteredUrlPatterns"
  *ngIf="show$ | async">
</ng-http-loader>
