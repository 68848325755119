import { ModuleWithProviders, NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ComplianceService } from "./compliance.service";
import { COMPLIANCE_TOKEN } from "./compliance.token";
import { ComplianceConfig } from "@premium-portal/types";

@NgModule({
  imports: [CommonModule],
})
export class IppComplianceModule {
  static forRoot(config: ComplianceConfig): ModuleWithProviders<IppComplianceModule> {
    const { environment } = config;
    if (!environment) {
      throw new Error("Environment is not set");
    }

    if (environment.isUnstable) {
      console.warn("You're pointing to an unstable Industry Premium Portal environment. This might lead to erratic behaviour.");
    }

    return {
      ngModule: IppComplianceModule,
      providers: [ComplianceService, { provide: COMPLIANCE_TOKEN, useValue: config }],
    };
  }
}
