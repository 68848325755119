/**
 * Set user's preferred language.
 *
 * If the user has a language saved in local storage, returns it.
 * Otherwise, the user's browser language is used by default.
 *
 * If we don't have a translation file for the browser language, we use English by default.
 * @param key to look for in the local storage
 * @param defaultLanguage
 */
import { LanguageCode, LanguageLabel } from "@premium-portal/types";

export const getCurrentLanguage = (key: string, defaultLanguage: LanguageCode, supportedLanguageLabel: LanguageLabel): LanguageCode => {
  const storedLanguage = localStorage.getItem(key);

  let userLanguage;
  try {
    userLanguage = storedLanguage ? JSON.parse(storedLanguage) : null;
  } catch (error) {
    console.debug(`⚠️ The retrieved language property is not a valid JSON.`);
  }

  // legacy auth-controller format - kept for retro compatibility
  if (userLanguage?.currentLanguage) {
    localStorage.setItem(key, JSON.stringify(userLanguage?.currentLanguage)); // update to new format
    userLanguage = userLanguage.currentLanguage;
  }

  /**
   * If retrieve language is part of the supported language
   */
  try {
    if (userLanguage && supportedLanguageLabel[userLanguage]) {
      return userLanguage;
    }
  } catch (error) {
    console.debug(`⚠️ User language doesn't exist in supported language list.`);
  }

  return defaultLanguage;
};
