import { Injectable } from "@angular/core";
import { ErrorTypes } from "./error-event-bus.service";
import { ToastService, ToastType } from "@siemens/ix-angular";
import { AppHttpErrorResponse } from "./models/app-http-error-response.model";
import { RoutingPathError } from "./enums/routing-path-error";
import { HttpErrorResponse } from "@angular/common/http";

declare interface ErrorDictionary {
  [key: number]: { ROUTE: string; ERROR: HttpErrorResponse; TYPE: string };
}

@Injectable({
  providedIn: "root",
})
export class ErrorService {
  constructor(private toastService: ToastService) {}

  public errorDictionary = (errorResponse: AppHttpErrorResponse<unknown>): ErrorDictionary => {
    const dictionary = {
      500: {
        ROUTE: RoutingPathError.ERROR_UNEXPECTED,
        ERROR: errorResponse,
        TYPE: ErrorTypes.PAGE,
      },
      503: {
        ROUTE: RoutingPathError.ERROR_SERVICE_UNAVAILABLE,
        ERROR: errorResponse,
        TYPE: ErrorTypes.PAGE,
      },
      502: {
        ROUTE: RoutingPathError.ERROR_SERVICE_UNAVAILABLE,
        ERROR: errorResponse,
        TYPE: ErrorTypes.PAGE,
      },
      504: {
        ROUTE: RoutingPathError.ERROR_SERVICE_UNAVAILABLE,
        ERROR: errorResponse,
        TYPE: ErrorTypes.PAGE,
      },
      404: {
        ROUTE: RoutingPathError.ERROR_NOT_FOUND,
        ERROR: errorResponse,
        TYPE: ErrorTypes.TOAST,
      },
      403: {
        ROUTE: RoutingPathError.ERROR_FORBIDDEN,
        ERROR: errorResponse,
        TYPE: ErrorTypes.PAGE,
      },
      401: {
        ROUTE: RoutingPathError.ERROR_UNAUTHORIZED,
        ERROR: errorResponse,
        TYPE: ErrorTypes.PAGE,
      },
      400: {
        ROUTE: RoutingPathError.ERROR_BAD_REQUEST,
        ERROR: errorResponse,
        TYPE: ErrorTypes.TOAST,
      },
    } as ErrorDictionary;

    return (
      dictionary[errorResponse.status] || {
        ROUTE: RoutingPathError.ERROR_SERVICE_UNAVAILABLE,
        ERROR: errorResponse,
        TYPE: ErrorTypes.TOAST,
      }
    );
  };

  public async show(origin = "", type: ToastType = "warning"): Promise<void> {
    const title = `Oops! Internal error...
      Looks like there is a temporary ${origin} issue.

      Please, try again later...`;

    this.toastService.setPosition("top-right");
    this.toastService.show({
      message: title,
      type,
      autoCloseDelay: 10000,
    });
  }
}
