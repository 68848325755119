<div class="notification">
  <div class="notification__list-container">
    <div class="notification__notification-list">
      <div
        *ngFor="let notification of notifications; let i = index"
        (click)="preventDefault($event)"
        class="notification__list-item"
        [ngStyle]="{'border-left-color': !notification.read ? severityLevel[notification.severity] : 'var(--theme-color-neutral)'}">
        <div class="notification__notification-container">
          <div class="notification__notification-content">
            <div class="notification__notification-message">
              <div class="notification__title" [attr.data-read]="!notification.read">{{ notification.title }}</div>
              <div class="notification__notification-text"> {{ notification.message | ellipsis: LIMIT_CHARACTERS}} </div>
              <div class="notification__notification-text" *ngIf="notification.message.length > LIMIT_CHARACTERS">
                <small>
                  {{ "IPP_NAVIGATION.MENU.NOTIFICATION_BELL.COMPREHENSIVE_DETAILS_PART1" | translate }}
                  <a (click)="navigateToNotificationsPage()" class="default-link">
                    {{ "IPP_NAVIGATION.MENU.NOTIFICATION_BELL.FULL_NOTIFICATIONS_PAGE" | translate }}
                  </a>
                </small>
              </div>
            </div>
            <div class="notification__actions">
              <div class="notification__notification-text-date"> {{ notification.created | amTimeAgo }} </div>
              <div class="button">
                <ix-icon-button
                  ghost
                  variant="Primary"
                  icon="check"
                  size="16"
                  (click)="notifyAsRead($event, notification)"
                  *ngIf="!notification.read" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
