import { damexComplianceGuard } from "@premium-portal/compliance";
import { countryRollOutGuard } from "@premium-portal/countries";
import { AppRoutes } from "./core/models";
import { authStateLoadedGuard } from "./core/guards/auth/auth-state-loaded.guard";
import { MainLayoutComponent } from "./core/layouts/main-layout/main-layout.component";
import { authGuard } from "./core/guards";
import { runSerially } from "./shared/guards/run-serially.guard";
import { RolledOutComponent } from "./legal-documents/components/rolled-out/rolled-out.component";
import { PageLoaderComponent } from "./core/components";

export const routes: AppRoutes = [
  {
    path: "",
    canActivate: [authStateLoadedGuard],
    component: MainLayoutComponent,
    children: [
      {
        path: "",
        loadChildren: () => import("../app/start-page/start-page.module").then((m) => m.StartPageModule),
      },
      {
        path: "welcome",
        canActivate: [authGuard, runSerially([damexComplianceGuard, countryRollOutGuard])],
        loadChildren: () => import("../app/welcome/welcome.module").then((m) => m.WelcomeModule),
      },
      {
        path: "rolled-out",
        data: { breadcrumbs: [] },
        component: RolledOutComponent,
      },
      {
        path: "user-preferences",
        loadChildren: () => import("../app/user-preferences/user-preferences.module").then((m) => m.UserPreferencesModule),
      },
      {
        path: "terms-and-conditions",
        data: { breadcrumbs: [] },
        loadChildren: () => import("../app/legal-documents/legal-documents.module").then((m) => m.LegalDocumentsModule),
      },
      {
        path: "whats-new",
        data: { breadcrumbs: [] },
        loadChildren: () => import("../app/whats-new/whats-new.module").then((m) => m.WhatsNewModule),
      },
      {
        path: "contacts",
        loadChildren: () => import("../app/contacts/contacts.module").then((m) => m.ContactsModule),
      },
      {
        path: "error",
        loadChildren: () => import("../app/error-page/error-page.module").then((m) => m.ErrorPageModule),
      },
      {
        path: "blocked",
        canActivate: [authGuard],
        loadChildren: () => import("../app/blocked/blocked.module").then((m) => m.BlockedModule),
      },
      {
        path: "notifications",
        canActivate: [authGuard],
        loadChildren: () => import("../app/notifications-page/notifications-page.module").then((m) => m.NotificationsPageModule),
      },
    ],
  },
  {
    path: "authorize",
    component: PageLoaderComponent,
  },
  { path: "**", redirectTo: "", pathMatch: "full" },
];
