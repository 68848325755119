import { ModuleWithProviders, NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { UcaAcceptanceService } from "./uca-acceptance.service";
import { UcaAcceptanceConfig } from "@premium-portal/types";
import { UCA_ACCEPTANCE_TOKEN } from "./uca-acceptance.token";

@NgModule({
  imports: [CommonModule],
})
export class IppUniversalCustomerAgreementModule {
  static forRoot(config: UcaAcceptanceConfig): ModuleWithProviders<IppUniversalCustomerAgreementModule> {
    const { environment } = config;
    if (!environment) {
      throw new Error("Environment is not set");
    }

    if (environment.isUnstable) {
      console.warn("You're pointing to an unstable Industry Premium Portal environment. This might lead to erratic behaviour.");
    }
    return {
      ngModule: IppUniversalCustomerAgreementModule,
      providers: [UcaAcceptanceService, { provide: UCA_ACCEPTANCE_TOKEN, useValue: config }],
    };
  }
}
