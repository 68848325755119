export enum RoutingPathError {
  ERROR_UNAUTHORIZED = "/error/unauthorized",
  ERROR_FORBIDDEN = "/error/forbidden",
  ERROR_SUBSCRIPTION = "/error/subscription",
  ERROR_BAD_REQUEST = "/error/bad-request",
  ERROR_NOT_FOUND = "/error/not-found",
  ERROR_UNEXPECTED = "/error/unexpected",
  ERROR_SERVICE_UNAVAILABLE = "/error/service-unavailable",
  ERROR = "/error",
}
