<ix-basic-navigation [applicationName]="appName">
  <ix-menu i18nLegal="{{ 'IPP_NAVIGATION.ABOUT_LEGAL_INFO.TITLE' | translate }}" #menu>
    <ix-menu-item icon="log-in" *ngIf="!isAuthenticated" (click)="login()">
      {{ "IPP_NAVIGATION.MENU.SIDEBAR.LOGIN" | translate }}
    </ix-menu-item>
    <ix-menu-avatar
      *ngIf="isAuthenticated"
      [initials]="initials"
      (logoutClick)="logout()"
      [top]="fullName"
      i18nLogout="{{ 'IPP_NAVIGATION.MENU.SIDEBAR.LOGOUT' | translate }}">
    </ix-menu-avatar>
    <ix-menu-item [home]="true" icon="home" (click)="navigate(appRoutes.ROOT)">
      {{ "IPP_NAVIGATION.MENU.SIDEBAR.HOME" | translate }}
    </ix-menu-item>

    <ipp-menu-item-notification-bell *ngIf="isAuthenticated">
      {{ "IPP_NAVIGATION.MENU.SIDEBAR.NOTIFICATIONS" | translate }}
    </ipp-menu-item-notification-bell>
    <ix-menu-item icon="mail" (click)="navigate(appRoutes.CONTACT)"> {{ "IPP_NAVIGATION.MENU.SIDEBAR.CONTACT" | translate }} </ix-menu-item>
    <ix-menu-item *ngIf="showAdminMenu" icon="maintenance-warning" (click)="navigateToAdministrationPage()">
      {{ "IPP_NAVIGATION.MENU.SIDEBAR.ADMINISTRATION" | translate }}
    </ix-menu-item>
    <ix-menu-item *ngIf="isAuthenticated" icon="cogwheel" slot="bottom" (click)="navigate(appRoutes.USER_PREFERENCES)">
      {{ 'IPP_NAVIGATION.MENU.SIDEBAR.USER_PREFERENCES' | translate }}
    </ix-menu-item>
    <ix-menu-item icon="library" slot="bottom" (click)="navigateToDocs()">
      {{ "IPP_NAVIGATION.MENU.PLATFORM_DOCUMENTATION" | translate }}
    </ix-menu-item>

    <ix-menu-about label="{{ 'IPP_NAVIGATION.ABOUT_LEGAL_INFO.TITLE' | translate }}">
      <ix-menu-about-item label="{{ 'IPP_NAVIGATION.ABOUT_LEGAL_INFO.VERSION' | translate : { v: version } }}">
        <p>© Siemens {{ currentYear }}</p>
        <div *ngFor="let info of legalInfo">
          <ng-container [ngSwitch]="info.type">
            <ix-link-button *ngSwitchCase="legalInfoType.HREF" [url]="info.url" target="_blank" rel="noopener" (click)="closeAbout()">
              {{ info.title | translate }}
            </ix-link-button>
            <ng-container *ngSwitchCase="legalInfoType.ROUTER_LINK">
              <ix-link-button *ngIf="localRouter" [routerLink]="info.url" (click)="closeAbout()">
                {{ info.title | translate }}
              </ix-link-button>
              <ix-link-button *ngIf="!localRouter" [url]="getUrl(info.url)" (click)="closeAbout()">
                {{ info.title | translate }}
              </ix-link-button>
            </ng-container>
          </ng-container>
        </div>
      </ix-menu-about-item>
    </ix-menu-about>
  </ix-menu>
  <div class="main-content">
    <router-outlet />
  </div>
</ix-basic-navigation>
