import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";

import { HttpStatusCode } from "../enums";
import { AppHttpErrorResponse, EmitEvent, ErrorEventBusService, Events } from "@premium-portal/events";

@Injectable()
export class AppHttpInterceptor implements HttpInterceptor {
  constructor(private errorEventBus: ErrorEventBusService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(catchError((error: HttpErrorResponse) => error && this.handleHttpErrorResponse(error)));
  }

  private handleHttpErrorResponse(httpError: HttpErrorResponse) {
    const errorResponse = this.generateError(httpError);
    errorResponse._wasInterceptedByApp = true;

    if (this.shouldIgnoreError(errorResponse)) {
      return throwError(() => errorResponse);
    }

    this.errorEventBus.emit(new EmitEvent(Events.ErrorSelected, errorResponse));

    return throwError(() => errorResponse);
  }

  private generateError(error: HttpErrorResponse): AppHttpErrorResponse {
    //Clone error object without blob
    const errorClone = JSON.stringify(error, (key, value) => {
      if (key !== "error" || error.error == null || !(error.error instanceof Blob)) {
        return value;
      }
      return null;
    });
    return JSON.parse(errorClone);
  }

  /**
   * Errors handled outside interceptor
   *
   * We must update this list for calls we want to
   * treat errors ourselves
   * */
  private shouldIgnoreError(errorResponse: AppHttpErrorResponse) {
    //Error handled outside interceptor
    const excludedList: ReadonlyArray<Readonly<[string, HttpStatusCode?]>> = [
      ["freemium-subscriptions", HttpStatusCode.ServiceUnavailable],
      ["gateway/userinfo", HttpStatusCode.Unauthorized],
      ["user/mall-account", HttpStatusCode.Unauthorized],
      ["user/uca-acceptance", HttpStatusCode.Unauthorized],
    ];

    return excludedList.some(([url, status]) => errorResponse.url.includes(url) && errorResponse.status === status);
  }
}
