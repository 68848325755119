import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";

import { SubscriberBase } from "../../abstract/subscriber-base";

@Component({
  selector: "app-main-layout",
  templateUrl: "./main-layout.component.html",
  styleUrls: ["./main-layout.component.scss"],
})
export class MainLayoutComponent extends SubscriberBase implements OnInit {
  constructor(private router: Router, private changeDetectorRef: ChangeDetectorRef, private translateService: TranslateService) {
    super();
  }

  ngOnInit(): void {
    this.addSub(
      this.translateService.onLangChange.subscribe(() => {
        this.changeDetectorRef.markForCheck();
      })
    );
  }

  /**
   * Use if for  general  small route changes operations.
   * for example closing modals handled by JQuerry.
   */
  onRouteChange(): void {
    if (typeof window.scrollTo === "function") window.scrollTo(0, 0); //Resets Scroll
  }
}
