import { CUSTOM_ELEMENTS_SCHEMA, ModuleWithProviders, NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";

import { MomentModule } from "ngx-moment";
import { NavigationMenuComponent } from "./navigation-menu/navigation-menu.component";
import { NotificationComponent } from "./notification-bell/notification/notification.component";
import { NotificationBellComponent } from "./notification-bell/notification-bell.component";
import { NotificationsSignalRService } from "./notification-bell/services/notifications-signal-r.service";
import { NavigationService } from "./navigation.service";
import { InternalNavigationConfig, NavigationConfig, TypesModule, Utils } from "@premium-portal/types";
import { IppThemesModule } from "@premium-portal/themes";
import { EllipsisPipe } from "./pipes/ellipsis.pipe";

@NgModule({
  imports: [
    TypesModule,
    RouterModule,
    MomentModule,
    IppThemesModule.forRoot(),
    EllipsisPipe,
    NavigationMenuComponent,
    NotificationBellComponent,
    NotificationComponent,
  ],
  exports: [NavigationMenuComponent, IppThemesModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class IppNavigationModule {
  /**
   * Configuration for sidebar navbar module
   * @param config
   */
  static forRoot(config: NavigationConfig | InternalNavigationConfig): ModuleWithProviders<IppNavigationModule> {
    const { environment } = config;
    if (!environment) {
      throw new Error("Environment is not set");
    }

    return {
      ngModule: IppNavigationModule,
      providers: [NotificationsSignalRService, NavigationService, { provide: Utils.NAVIGATION_CONFIG, useValue: config }],
    };
  }
}
