import { Component, ElementRef, EventEmitter, Inject, Input, OnChanges, OnInit, Output, Renderer2, ViewChild } from "@angular/core";
import { checkRequiredFields, LibRoutes, LinkType, NavigationConfig, NavigationLink, UserInfo, Utils } from "@premium-portal/types";
import { NavigationService } from "../navigation.service";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { CommonModule } from "@angular/common";
import { IxModule } from "@siemens/ix-angular";
import { RouterLink, RouterOutlet } from "@angular/router";
import { NotificationBellComponent } from "../notification-bell/notification-bell.component";

@Component({
  selector: "ipp-navigation",
  templateUrl: "./navigation-menu.component.html",
  styleUrls: ["./navigation-menu.component.scss"],
  standalone: true,
  imports: [CommonModule, IxModule, TranslateModule, RouterLink, RouterOutlet, NotificationBellComponent],
})
export class NavigationMenuComponent implements OnInit, OnChanges {
  @Input() appName = "Industry Premium Portal";

  /**
   * User profile information
   */
  @Input() userInfo!: UserInfo;

  /**
   * Emit event on user log in button clicked
   */
  @Output() signInClicked = new EventEmitter<void>();

  /**
   * Emit event on user log out button clicked
   */
  @Output() signOutClicked = new EventEmitter<void>();

  /**
   * Version of the application
   * @required
   */
  @Input() version!: string;

  /**
   * Legal Info Links
   */
  @Input() legalInfo: NavigationLink[] = this.getDefaultLegalInfos();

  public appRoutes: typeof LibRoutes = LibRoutes;
  public legalInfoType: typeof LinkType = LinkType;
  public localRouter: boolean;
  protected currentYear = new Date().getFullYear();
  protected isAuthenticated = false;
  protected showAdminMenu = false;

  @ViewChild("menu", { read: ElementRef })
  menuRef!: ElementRef<HTMLIxMenuElement>;

  constructor(
    protected navigationService: NavigationService,
    protected translate: TranslateService,
    @Inject(Utils.NAVIGATION_CONFIG) private config: NavigationConfig,
    private el: ElementRef,
    private renderer: Renderer2
  ) {
    this.localRouter = config.shouldUseLocalRouter;
  }

  ngOnInit(): void {
    this.navigationService.isAuthenticated().subscribe((res) => {
      this.isAuthenticated = res;
      this.showAdminMenu = this.isAuthenticated && this.navigationService.getUserInfo().permissions.includes("users:read");
    });
    this.translate.onLangChange.subscribe(() => (this.legalInfo = this.getDefaultLegalInfos()));
  }

  public login(): void {
    this.signInClicked.emit();
  }

  public logout(): void {
    this.signOutClicked.emit();
  }

  public ngOnChanges(): void {
    this.navigationService.setUserInfo(this.userInfo);
    checkRequiredFields("version", this.version);
  }

  public async closeAbout() {
    const { nativeElement } = this.menuRef;
    await nativeElement.toggleAbout(false);
  }

  public navigate(route: LibRoutes): void {
    this.tryCloseDropdownIfOpen();

    this.navigationService.navigate(route);
  }

  public navigateToAdministrationPage(): void {
    const url = `${this.config.environment.url}administration`;
    window.open(url, "_blank");
  }

  public navigateToDocs(): void {
    this.tryCloseDropdownIfOpen();
    const url = new URL("https://industry-premium-portal.siemens.io");
    window.open(url, "_blank");
  }

  public getUrl(urlPath: string): string {
    const formPath = urlPath.substring(1);
    const url = new URL(`${this.config.environment.url}${formPath}`);
    return url.toString();
  }

  protected get initials(): string {
    return `${this.userInfo?.firstName?.charAt(0)}${this.userInfo?.lastName?.charAt(0)}`;
  }

  protected get fullName(): string {
    return `${this.userInfo?.firstName} ${this.userInfo?.lastName}`;
  }

  private getDefaultLegalInfos(): NavigationLink[] {
    return [
      {
        title: "IPP_NAVIGATION.ABOUT_LEGAL_INFO.CORPORATE_INFORMATION.TITLE",
        url: this.translate.instant("IPP_NAVIGATION.ABOUT_LEGAL_INFO.CORPORATE_INFORMATION.URL"),
        type: LinkType.HREF,
      },
      {
        title: "IPP_NAVIGATION.ABOUT_LEGAL_INFO.PRIVACY_NOTICE.TITLE",
        url: this.translate.instant("IPP_NAVIGATION.ABOUT_LEGAL_INFO.PRIVACY_NOTICE.URL"),
        type: LinkType.HREF,
      },
      {
        title: "IPP_NAVIGATION.ABOUT_LEGAL_INFO.TERMS_AND_CONDITIONS",
        url: "/terms-and-conditions",
        type: LinkType.ROUTER_LINK,
      },
      {
        title: "IPP_NAVIGATION.ABOUT_LEGAL_INFO.COOKIE_NOTICE.TITLE",
        url: this.translate.instant("IPP_NAVIGATION.ABOUT_LEGAL_INFO.COOKIE_NOTICE.URL"),
        type: LinkType.HREF,
      },
      {
        title: "IPP_NAVIGATION.ABOUT_LEGAL_INFO.DIGITAL_ID.TITLE",
        url: this.translate.instant("IPP_NAVIGATION.ABOUT_LEGAL_INFO.DIGITAL_ID.URL"),
        type: LinkType.HREF,
      },
      {
        title: "IPP_NAVIGATION.ABOUT_LEGAL_INFO.THIRD_PARTY_TERMS",
        url: "/assets/ReadMe_OSS.html",
        type: LinkType.HREF,
      },
      {
        title: "IPP_NAVIGATION.ABOUT_LEGAL_INFO.WHATS_NEW",
        url: "/whats-new",
        type: LinkType.ROUTER_LINK,
      },
      {
        title: "IPP_NAVIGATION.ABOUT_LEGAL_INFO.ROLLED_OUT.TITLE",
        url: "/rolled-out",
        type: LinkType.ROUTER_LINK,
      },
    ];
  }

  private tryCloseDropdownIfOpen(): void {
    const shadowRoot = this.el.nativeElement.querySelector("ix-menu-avatar")?.shadowRoot;
    if (shadowRoot) {
      const ixDropdown = shadowRoot.querySelector("ix-dropdown");
      if (ixDropdown) {
        ixDropdown.removeAttribute("show"); // class uses by ix to show overlay
        this.renderer.removeClass(ixDropdown, "show");
      }
    }
  }
}
