import { ModuleWithProviders, NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { IppBreadcrumbsComponent } from "./ipp-breadcrumbs.component";
import { RouterModule } from "@angular/router";
import { IppBreadcrumbsService } from "./services/ipp-breadcrumbs.service";

@NgModule({
  imports: [CommonModule, RouterModule],
  declarations: [IppBreadcrumbsComponent],
  exports: [IppBreadcrumbsComponent],
})
export class IppBreadcrumbsModule {
  private static _initializedForRoot = false;
  static forRoot(): ModuleWithProviders<IppBreadcrumbsModule> {
    if (IppBreadcrumbsModule._initializedForRoot) {
      console.error("IppBreadcrumbsModule.forRoot() called more than once!");
    } else {
      IppBreadcrumbsModule._initializedForRoot = true;
    }
    return {
      ngModule: IppBreadcrumbsModule,
      providers: [IppBreadcrumbsService],
    };
  }
  static forChild(): ModuleWithProviders<IppBreadcrumbsModule> {
    return {
      ngModule: IppBreadcrumbsModule,
      providers: [],
    };
  }
}
